export class ViewText {
                                                                                    
  RequestVisitorPassText: string = 'Request a Wingmate Pass to'; //--
  NameMustMatchYourText: string = "Name must match your <a href='https://www.tsa.gov/travel/security-screening/identification' target='_blank'>TSA-approved photo ID</a>"; //--
  WhenAreYouComingToVisitText: string = "When are you coming to visit?";//--
  AvailableSecurityTimesText: string = "Available Security Times"; //--
  VisitorText: string = "Visitor"; //--
  InformationText: string = "Information"; //--
  MustMatchExactlyToYourIDText: string = "Must match exactly to your ID"; //--
  FirstNameText: string = "First Name"; //--
  LastNameText: string = "Last Name"; //--
  DateOfBirthText: string = "Date of Birth"; //-- 
  GenderText: string = "Gender"; //--
  MaleText: string = "MALE"; //--
  FemaleText: string = "FEMALE"; //--
  UndisclosedText: string = "UNDISCLOSED"; //-- 
  UnspecifiedText: string = "UNSPECIFIED"; //--
  EmailText: string = "Email"; //--
  ConfirmEmailText: string = "Confirm Email"; //--
  ReasonForVisitText = "Reason For Visit"; //--
  EscortFamilyFriendDepartingPHLText: string = "Escort family/friend departing PHL"; //--
  GreetFamilyFriendArrivingAtPHLText: string = "Greet family/friend arriving at PHL"; //-
  DineShopAtPHLText: string = "Dine/Shop at PHL"; //--
  AttendPHLEventText: string = "Attend a PHL Event"; //--
  AviationSiteSeeingText: string = "Aviation site-seeing"; //--
  ViewPHLArtExhibitionsText: string = "View PHL Art Exhibitions"; //--
  AddAnotherVisitorText: string = "ADD ANOTHER VISITOR"; //--
  RemoveVisitorText: string = "REMOVE VISITOR"; //--
  IAcceptPhlWingmatePassTermsOfUseText: string = "I accept PHL Wingmate Pass Terms of Use"; //--
  SubmitRequestText: string = "SUBMIT REQUEST"; //--
  EditButtonText: string = "Edit"; //Will revisit
  SubmitButtonText: string = "Submit"; //Will revisit
  YourWingmatePassApplicationHasBeenReceivedText: string = "Your Wingmate Pass application has been received!"; //--
  VisitDetailsText: string = "Visit Details";  //--
  PhoneText: string = "Phone"; //--
  ArrivalWindowText: string = "Arrival Window"; //--


  ConfirmText: string = "Confirm";
  PleaseVerifyYourInformationText: string = "Please verify your information matches <b>exactly</b> to your ID"; 
  DateText: string = "Date"; 
  VisitorConfirmedText: string = "Visitor(s) confirmed:"; 
  ReturnToHomePageButtonText: string = "Return to Home Page"; 
  PleaseReadOurText: string = "Please read our <a href='https://phl.org/wingmate/FAQ' class='link-text' target='_blank'> Wingmate Pass FAQ’s</a> and know the <a href='https://www.tsa.gov/travel/security-screening/whatcanibring/all' class='link-text' target='_blank'>TSA Prohibited Items</a>.";
  PleaseCheckYourEmailForAdditionalInfoText: string = "Please check your email for additional information from <a href='mailto:phl@wingmate-pass.com'>phl@wingmate-pass.com</a> regarding your application status.";
  SurveyText: string = "We’d love your input! Take our <a href='https://www.surveymonkey.com/r/phlwingmatepass' target='_blank'>survey</a> to share how you plan to use The PHL Wingmate Pass. We appreciate your invaluable feedback.";

  PleaseNoteTheFollowingText: string = "Please note the following before submitting your application:";
  YourNameDateText: string = "Your name, date of birth and gender must match your <a href='https://www.tsa.gov/travel/security-screening/identification' target='_blank'>TSA-approved photo ID</a> exactly. Please double check before submitting your application.";
  YouWillOnlyBeGrantedAcessText: string = "You will only be granted access at the security checkpoint and during the time slot chosen on this application. Once through security you may access all PHL terminals (A-West, A-East, B, C, D, E & F)";
  ApplicationStatusText: string = "Application Status: If you are applying for same day entry, you will receive your application status in about 15 minutes. If you applied in advance, you will receive an email after 12:00 AM on the day of your visit.";
  OnceThroughSecurityText: string = "Once through security you may access all PHL terminals (A-West, A-East, B, C, D, E & F)";

  PrimaryReasonForEscortingText: string = "Reason for escorting/greeting a loved one";
  ProvideAssistanceToDisabledText: string = "Provide disability or mobility assistance";
  EscortMinorText: string = "Escort a child/minor";
  ProvideAssistanceToNonEnglishSpeakerText: string = "Provide assistance to a non-English speaker";
  HomecomingText: string = "Surprise/Homecoming";
  SpendTimeText: string = "Spend time with a loved one";
  ProvideExtraAssistanceText: string = "Provide extra assistance";
  OtherText: string = "Other";

  SpecifyUseOfWingmatePassText: string = "Please specify your use of the Wingmate Pass program";
  WhichDiningShoppingLocationText: string = "Which dining and/or shopping location do you plan to visit?";
  WhichPHLEventText: string = "Which PHL Event are you attending?";
}
