import { Component, OnInit } from '@angular/core';
import {
  defineComponents,
  DocumentReaderDetailType,
  DocumentReaderService,
} from '../../../assets/main.js';

@Component({
  selector: 'app-root',
  templateUrl: 'regula.component.html',
  styleUrls: ['regula.component.css']
})
export class RegulaComponent implements OnInit {

  public isOpen: boolean = false;

  ngOnInit() {
    window.RegulaDocumentSDK = new DocumentReaderService();
    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        multipageProcessing: true,
      },
    };

    defineComponents().then(() => window.RegulaDocumentSDK.initialize());

    // To use the document-reader component on test environments, you have to set the base64 license
    defineComponents().then(() => window.RegulaDocumentSDK.initialize({ license: 'AAEAAG2DLjHVPLctKM0FHfXIxD5FWLV/T+crl28q4id9TkXN++15eMDwuwYX8gvMWubcr80Q7sEvkz7PYGluHUgVIpEmip/yQvp5ck5Y/qo18lpv9JU+Ir4MrftV08xQB6XwJVRERRONoeNVKf0ouIfYWg09/V8b378n+3k+WRA1aEBmsCCIssZlO2eXNJ8Bv0WE5QYNCJudSn96Ij2eSivkuHCTOHmsPBgyHii1FHv9h3Yc1VNlBWHfwp5mXhVWKHnGYAH0ahgWFp9i17U8UyJxENIf/ELbRRMrJQomNK6aPH8w8oZN3BaNoBNfsYRseKQIBymTCPUvChDu5ZZWbRioLlDkAAAAAAAAEMHQRqkil9z1nptlQjOCQGQam54C+pii++9KGKx5UCiYmJPVbVhTPDOKbzHAea8FxA/mEXI/QnQQELHId3o4+1LKFCOEvxrV6tV+vCBZ/8GKuWsJdbqrvpiGDshN8ixrt5FIp7s01Xs8Ip2kaNoi7CS3epsPquY3NBYzt+CyxDF/kNdNdD5ZMh3y8VKj02xFihXCuJDYVwOKNQAjcNkVzibOlNLLqFfdCMMFMPc9mabXh5RSsJoKY1CKOu1em3P6rmMXwS7sIXiUpKqZtA6b8QB0UdPxhwO2ayzbkf0QkmlW' }));

  }

  public handleDataRead(data: CustomEvent<DocumentReaderDetailType>) {
    if (data.detail.action === 'PROCESS_FINISHED') {
      const status = data.detail.data.status;
      const isFinishStatus = status === 1 || status === 2;

      if (!isFinishStatus || !data.detail.data.response) return;
      console.log(data.detail.data.response);
    }

    if (data.detail.action === 'CLOSE') {
      this.isOpen = false;
    }
  }

}
